var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.snackbar ? _c('v-snackbar', {
    attrs: {
      "color": _vm.snackbar.color,
      "timeout": _vm.snackbar.timeout || 2000,
      "vertical": _vm.snackbar.vertical
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Close ")])];
      }
    }], null, false, 2958781529),
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }