<template>
  <v-snackbar
    v-if="snackbar"
    v-model="snackbar"
    :color="snackbar.color"
    :timeout="snackbar.timeout || 2000"
    :vertical="snackbar.vertical"
  >
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: null,
    };
  },
  created() {
    this.EventBus.$on("notification", (data) => {
      this.snackbar = data;
    });
  },
};
</script>

<style lang="scss" scoped></style>
